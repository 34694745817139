import type { EventProps } from "../_model";

export default function EventCard(props: EventProps) {
  return (
    <div class="flex w-full max-w-[26rem] flex-col rounded-xl bg-white border-l-10 border-solid border#orange my-4 px-3 py-3">
      <p class="text-14px py-1 text#orange children:()">
        <span>
          {new Date(props.start).toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })}
        </span>{" "}
        -{" "}
        <span>{new Date(props.end).toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })} </span>
      </p>
      <p class="text-black text-18px py-1">{props.title}</p>
    </div>
  );
}
